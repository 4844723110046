<template>
  <div class="container">
    <div class="rechargeHeader flex jus-bet">
      <h3>{{ $t("market.recharge") }}</h3>
      <div class="cur-p" @click="router.push('/rechargeDetail')">
        <span>{{ $t("market.rechargeTips") }}</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="assetsBox flex">
      <div class="assetsLeft flex-2">
        <h4>{{ $t("tips.pleaseRechargeChoose") }}</h4>
        <div class="nameChooseBox">
          <el-radio-group v-model="rechargeType" size="small">
            <el-radio-button
              v-for="(item, index) in rechargeTypeList"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </div>
        <template v-if="rechargeType == '1'">
          <h4>{{ $t("market.recharge") }}</h4>
          <div class="chainBox flex">
            <el-select
              v-model="chainType"
              size="small"
              :placeholder="$t('market.chainChoosePlac')"
            >
              <el-option
                v-for="item in chainList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <h3 style="margin-top: 20px">
            {{ $t("market.xxpectedRechargeTitle") }}
          </h3>
          <h2>23423</h2> -->
          <img
            :src="addressImg"
            style="width: 110px; height: 110px; margin-top: 20px"
          />
          <h4>{{ tip }}</h4>
          <h4 style="margin-top: 20px">{{ $t("tips.pleaseRechargeInput") }}</h4>
          <el-input-number
            v-model="chainNum"
            size="small"
            :min="0"
            :placeholder="$t('market.chainNumPlac')"
            controls-position="right"
          ></el-input-number>
          <h4 style="margin-top: 20px">
            {{ $t("market.uploadImgText") }}
          </h4>
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeImgUpload"
            :headers="header"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>

        <el-button type="primary" class="submitBtn" @click="submit">{{
          $t("market.recharge")
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getkf, get_in_address, charge_req } from "@/api/user";
import { ref, getCurrentInstance, onMounted, computed } from "vue";
import { useOption } from "@/hooks/useOption";
import useUploadFile from "@/hooks/useUploadFile";
import { useRouter, useStore } from "@/utils/vueApi";
import { Message } from "element-ui";
import { useLang } from "@/hooks/useLang";
const store = useStore();
const { action, beforeImgUpload, header } = useUploadFile();
const { lang } = useLang();
const router = useRouter();
const vm = getCurrentInstance();
const { rechargeTypeList, chainList } = useOption();
// 充值类型
const rechargeType = ref(null);
rechargeType.value = rechargeTypeList.value[0].value;
// 客服跳转链接
const urlList = ref([]);
// 币种
const chainType = ref(null);
chainType.value = chainList.value[0].value;
// 数量
const chainNum = ref(null);
const imageUrl = ref(null);
const handleAvatarSuccess = (response, file) => {
  const { message } = response;
  imageUrl.value = message;
};
const submit = async () => {
  if (rechargeType.value == "1") {
    if (!chainNum.value) {
      Message.error(vm.proxy.$t("market.chainNumPlac"));
      return;
    }
    if (!imageUrl.value) {
      Message.error(vm.proxy.$t("market.uploadImgText"));
      return;
    }
    const postData = {
      currency: 3,
      type: 1,
      account: "1",
      lusdt: chainType.value,
      amount: chainNum.value,
      req_pic: imageUrl.value,
      lang: lang.value,
    };
    const { type, message } = await charge_req(postData);
    if(type == 'ok') {
      Message.success(vm.proxy.$t(message));
    } else {
      Message.error(vm.proxy.$t(message));
    }
  } else {
    window.open(urlList.value[rechargeType.value - 1], "_blank");
  }
};
const addressObj = ref({});
// 地址
const tip = computed(() => {
  console.log(chainType.value)
  if (chainType.value == "ERC20") {
    return addressObj.value.erc20;
  } else if (chainType.value == "TRC20") {
    return addressObj.value.trc20;
  } else if (chainType.value == "USDC") {
    return addressObj.value.omni;
  }
});
const addressImg = computed(() => {
  if (chainType.value == "ERC20") {
    return addressObj.value.erc20_pic;
  } else if (chainType.value == "TRC20") {
    return addressObj.value.trc20_pic;
  } else if (chainType.value == "USDC") {
    return addressObj.value.omni_pic;
  }
});
onMounted(async () => {
  const { message } = await getkf();
  urlList.value = message;
  const { message: data } = await get_in_address({
    lang: lang.value,
    currency: 3,
    user_id: store.state.user.userInfo.id
  });
  addressObj.value = data;
});
</script>

<style lang="scss" scoped>
.container {
  padding: 25px 240px;
  box-sizing: border-box;
  .rechargeHeader {
    padding: 0 24px;
    box-sizing: border-box;
    h3 {
      font-weight: bold;
      font-size: 28px;
    }
    div {
      color: #5670f6;
    }
  }
  .assetsBox {
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 30px;
    box-shadow: $box-shadow;
    background-color: $section-color;
    border: 1px solid v$market-list-hover;
    border-radius: 10px;
    padding: 20px;
    h4 {
      font-size: 14px;
    }
    & > div {
      padding: 0 15px;
      box-sizing: border-box;
    }
    h3 {
      font-size: 16px;
    }
    .nameChooseBox {
      margin-top: 8px;
      margin-bottom: 20px;
      :deep(.el-radio-button__inner) {
        background-color: $section-color;
      }
      :deep(.el-radio-button__orig-radio:checked + .el-radio-button__inner) {
        background-color: #5670f6;
        color: #fff;
        border-color: #5670f6;
      }
    }
    h2 {
      color: #5670f6;
      font-size: 32px;
      font-weight: bold;
    }
    :deep(.el-upload) {
      border: 1px dashed $market-list-hover;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .submitBtn {
      margin-top: 20px;
      width: 50%;
      background-color: #5670f6;
    }
  }
}
</style>
